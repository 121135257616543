.navbar-default {
    background-color: $navbar-background;
    border-width: 0;
    box-shadow: 0 1px 5px rgba($navbar-shadow, 0.6);
    .navbar-nav {
        >li {
            >a {
                @include transition-duration(.6s);
                @include transition-property(padding);
                @include transition-timing(cubic-bezier(0.19, 1, 0.22, 1));
                color: $navbar-text;
                font-weight: 700;
                padding-bottom: 15px;
                padding-top: 15px;
                text-align: center;
                text-transform: uppercase;
                @media (min-width: $screen-sm-min) {
                    font-size: 15px;
                    padding-bottom: 25px;
                    padding-top: 25px;
                }
                &:focus {
                    background-color: transparent;
                    color: $navbar-text;
                }
                &:hover {
                    background-color: $navbar-hover;
                    color: $navbar-text;
                }
            }
        }
    }
    .navbar-brand {
        @include transition-duration(.6s);
        @include transition-property(height);
        @include transition-timing(cubic-bezier(0.19, 1, 0.22, 1));
        height: 50px;
        @media (min-width: $screen-sm-min) {
            height: 70px;
        }
        >img {
            max-height: 100%;
            width: auto;
        }
    }
    .navbar-toggle {
        border-radius: 0;
        border-width: 0;
        &:focus,
        &:hover {
            background-color: $navbar-hover;
        }
        .icon-bar {
            background-color: $navbar-text;
        }
    }
    .navbar-collapse {
        border-width: 0;
        box-shadow: none;
    }
}

.navbar-shrink {
    .navbar-nav {
        >li {
            >a {
                @media (min-width: $screen-sm-min) {
                    padding-bottom: 15px;
                    padding-top: 15px;
                }
            }
        }
    }
    .navbar-brand {
        @media (min-width: $screen-sm-min) {
            height: 50px;
        }
    }
}