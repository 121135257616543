.scroll-up {
    background-color: $scroll-up-background;
    border-radius: 50%;
    bottom: 15px;
    box-shadow: 0 1px 8px rgba($scroll-up-shadow, 0.5);
    display: none;
    height: 40px;
    position: fixed;
    right: 15px;
    text-align: center;
    width: 40px;
    z-index: 999;
    .fa {
        color: $scroll-up-text;
        font-size: 15px;
        line-height: 40px;
    }
}