/* Media queries */

$screen-xs-min: 480px; // Extra small devices (mobile phones, 480px and up)
$screen-sm-min: 768px; // Small devices (tablets, 768px and up)
$screen-md-min: 992px; // Medium devices (desktops, 992px and up)
$screen-lg-min: 1200px; // Large devices (large desktops, 1200px

/* Paths to images */

$image-path: "../img/";

/* Colors */

$color-1: #53AFCD;
$color-2: #000000;
$color-3: #ffffff;
$color-primary: #338FAD;

/* Typography */

$font-family-sans-serif: "Open Sans",
sans-serif;
$font-family-fontawesome: "FontAwesome",
sans-serif;

/* Header */

$navbar-background: $color-primary;
$navbar-hover: $color-1;
$navbar-shadow: #000000;
$navbar-text: $color-3;

/* Intro */

$intro-background: $color-primary;
$intro-logo-background: $color-1;
$intro-text: $color-3;
$intro-text-border: $intro-text;
$scroll-down-background: $color-primary;
$scroll-down-mobile-background: $color-1;
$scroll-down-text: $color-3;

/* Scroll up */

$scroll-up-background: $color-primary;
$scroll-up-shadow: $color-2;
$scroll-up-text: $color-3;

/* Preloader */

$preloader-background-color: $color-primary;
$preloader-spinner-border: $color-1;
$preloader-spinner-background: $color-1;