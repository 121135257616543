@charset "UTF-8";
body {
    color: #626262;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
}

section {
    padding-top: 20px;
    @media (min-width: $screen-sm-min) {
        padding-top: 60px;
    }
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    color: $color-primary;
    font-weight: 300;
    text-transform: uppercase;
}

.h1,
.h2,
.h3,
h1,
h2,
h3 {
    margin-top: 30px;
    margin-bottom: 15px;
}

.h1,
h1 {
    font-size: 38px;
}

.h2,
h2 {
    font-size: 32px;
}

.h3,
h3 {
    font-size: 26px;
}

p {
    margin: 0 0 20px;
}

.btn {
    border-width: 0;
    display: inline-block;
    font-size: 14px;
    padding: 10px 15px;
}

.btn-lg {
    padding: 15px 20px;
    font-size: 16px;
    border-radius: 0;
}

.btn-primary {
    background-color: $color-1;
}

hr {
    background-color: $color-primary;
    height: 1px;
    margin: 15px 0;
    width: 200px;
}

.features {
    margin-top: 60px;
    .iconbox {
        text-align: center;
        >.fa {
            font-size: 80px;
        }
    }
}

.where-to-hide {
    margin-top: 60px;
    img {
        margin-top: 30px;
        @media (min-width: $screen-md-min) {
            margin-top: 0;
        }
    }
    h3 {
        text-align: center;
        @media (min-width: $screen-md-min) {
            text-align: left;
        }
    }
    .media-object {
        border-radius: 50%;
        border: 5px solid #eee;
        box-shadow: 0 3px 2px rgba(0, 0, 0, 0.3);
    }
}

.skills {
    margin-top: 30px;
    >div {
        margin-top: 30px;
    }
    .chart {
        display: inline-block;
        height: 140px;
        position: relative;
        text-align: center;
        width: 140px
    }
    .chart canvas {
        left: 0;
        position: absolute;
        top: 0
    }
    .percent {
        display: inline-block;
        font-size: 32px;
        font-weight: 300;
        line-height: 140px;
        z-index: 2;
        &::after {
            content: "%";
            font-size: 50%;
            margin-left: 0.1em;
        }
    }
}

.reviews {
    .carousel-indicators {
        bottom: auto;
        left: 0;
        margin: 0;
        right: 0;
        top: 0;
        width: auto;
        li {
            background-color: $color-primary;
            height: 15px;
            margin: 0 5px;
            vertical-align: middle;
            width: 15px;
            &.active {
                height: 20px;
                width: 20px;
            }
        }
    }
    .carousel-inner {
        margin-top: 30px;
        .item {
            .carousel-caption {
                border-bottom: 1px dashed $color-1;
                border-top: 1px dashed $color-1;
                color: #626262;
                font-weight: 300;
                margin-left: -15px;
                margin-right: -15px;
                margin-top: 40px;
                overflow: hidden;
                padding: 30px 0;
                position: static;
                text-align: left;
                text-shadow: none;
            }
        }
    }
    blockquote {
        border-left-width: 0;
        font-size: 20px;
        font-style: italic;
        margin: 0 0 20px;
        padding: 0 0 0 85px;
        &::before {
            content: "\f10d";
            font-family: $font-family-fontawesome;
            font-size: 60px;
            font-style: normal;
            left: 15px;
            line-height: 68px;
            position: absolute;
            top: 0;
        }
    }
    .author {
        color: $color-primary;
        font-weight: 400;
        margin: 0;
        text-align: right;
    }
}

.shop {
    &__logo {
        display: block;
        margin: 60px 0 30px;
    }
    &__link {
        margin: 0;
    }
    &__phone {
        margin-top: 30px;
        text-transform: uppercase;
        span {
            display: block;
            font-size: 24px;
            font-weight: 400;
        }
        a {
            display: inline-block;
            font-size: 32px;
            font-weight: 300;
        }
    }
}

.footer {
    background-color: #222222;
    color: #ffffff;
    margin-top: 30px;
    padding: 40px 0;
    text-align: center;
    @media (min-width: $screen-sm-min) {
        margin-top: 60px;
    }
}

.footer-menu {
    list-style-image: none;
    list-style-position: outside;
    list-style-type: none;
    margin-bottom: 20px;
    padding: 0;
}

.footer-menu>li {
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
    vertical-align: middle;
}

.footer-menu>li>a {
    color: #ffffff;
}

.copyright {
    margin: 0
}

.copyright a {
    border-bottom: 1px dotted #fff
}

#footer span {
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    color: #ff5252
}

.social-links {
    list-style: none;
    padding: 0;
    margin: 0 0 20px;
}

.social-links li {
    display: inline-block;
    margin: 5px
}

.social-links a {
    border-radius: 3px;
    border: 1px solid $color-1;
    color: $color-1;
    display: block;
    font-size: 16px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    width: 40px;
}

.social-links a:hover {
    border: 1px solid $color-1;
    color: $color-1;
}


/* Article */

article {
    padding-top: 60px;
    @media (min-width: $screen-sm-min) {
        padding-top: 90px;
    }
}

article header {
    background-color: transparent;
}

.terms-conditions>ol {
    list-style-position: inside;
    list-style-type: upper-roman;
    margin: 30px 0 0;
    padding-left: 30px;
}

.terms-conditions>ol li {
    margin: 0 0 10px;
}

.terms-conditions>ol ul {
    list-style-position: inside;
    list-style-type: lower-alpha;
    padding-left: 30px;
}