.offer {
    margin-top: 30px;
    padding-top: 30px;
    text-align: center;
    figcaption {
        background-color: $color-primary;
        border-top: 10px solid $color-1;
        color: $color-3;
        margin-top: 15px;
        padding: 30px 20px;
    }
    h3 {
        color: $color-3;
        margin-bottom: 15px;
        margin-top: 0;
    }
    ul {
        list-style-image: none;
        list-style-position: outside;
        list-style-type: none;
        padding: 0;
        margin-bottom: 20px;
        li {
            font-weight: 300;
        }
    }
}