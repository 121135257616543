@-webkit-keyframes drop {
    0% {
        top: 0px;
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
    5%,
    95% {
        top: 15px;
        opacity: 1;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    }
    100% {
        top: 30px;
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
}

@keyframes drop {
    0% {
        top: 0px;
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
    5%,
    95% {
        top: 15px;
        opacity: 1;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    }
    100% {
        top: 30px;
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
}

.intro {
    position: relative;
    &--banner {
        background-image: url($image-path + "intro-background.jpg");
        background-position: center top;
        background-repeat: no-repeat;
        background-size: cover;
    }
    &__wrapper {
        left: 15px;
        margin: auto;
        position: absolute;
        right: 15px;
        @media (min-width: $screen-md-min) {
            background-color: rgba($intro-background, 0.9);
            border-radius: 0 20px 20px 0;
            left: 0;
            overflow: hidden;
            right: 40%;
        }
    }
    &__content {
        background-color: rgba($intro-background, 0.9);
        border-radius: 20px;
        padding: 20px;
        @media (min-width: $screen-md-min) {
            background-color: transparent;
            float: right;
            padding: 60px 30px;
        }
    }
    &__logo {
        background-color: $intro-logo-background;
        border-radius: 10px;
        height: 100px;
        margin: 0 auto;
        padding: 20px;
        width: 100px;
        @media (min-width: $screen-md-min) {
            display: inline-block;
            height: 180px;
            margin: 0 30px 0;
            vertical-align: middle;
            width: 180px;
        }
        img {
            display: block;
            max-height: 100%;
            width: auto;
        }
    }
    &__text {
        margin-top: 20px;
        @media (min-width: $screen-md-min) {
            border-left: 2px solid $intro-text-border;
            display: inline-block;
            margin-top: 0;
            padding-left: 30px;
            vertical-align: middle;
            width: 290px;
        }
        @media (min-width: $screen-lg-min) {
            width: 400px;
        }
        h1 {
            color: $intro-text;
            font-weight: 800;
            margin-top: 0;
            text-align: center;
            text-transform: uppercase;
            @media (min-width: $screen-md-min) {
                text-align: left;
            }
            /*span {
                display: block;
                font-weight: 800;
            }*/
        }
        p {
            color: $intro-text;
            font-size: 16px;
            font-weight: 300;
            margin: 0;
            text-align: center;
            @media (min-width: $screen-md-min) {
                text-align: left;
            }
        }
    }
    &__scroll-down {
        background-color: $scroll-down-mobile-background;
        border-radius: 50%;
        display: block;
        height: 60px;
        margin: 15px auto 0 auto;
        text-align: center;
        width: 60px;
        z-index: 10;
        @media (min-width: $screen-md-min) {
            background-color: rgba($scroll-down-background, 0.9);
            bottom: 40px;
            left: 0;
            margin: 0 auto;
            position: absolute;
            right: 0;
        }
        .intro__content & {
            @media (min-width: $screen-md-min) {
                display: none;
            }
        }
        &>.fa {
            -webkit-animation-delay: 0s;
            animation-delay: 0s;
            -webkit-animation-duration: 5s;
            animation-duration: 5s;
            -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
            -webkit-animation-name: drop;
            animation-name: drop;
            -webkit-animation-play-state: running;
            animation-play-state: running;
            -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
            color: $scroll-down-text;
            font-family: $font-family-fontawesome;
            font-size: 32px;
            position: relative;
        }
    }
    &>&__scroll-down {
        display: none;
        @media (min-width: $screen-md-min) {
            display: initial;
        }
    }
}